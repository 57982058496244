import React from "react";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Navbars() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
      <h1 id="architecture" style={{marginTop: "100px", textAlign: "center"}}> INTRODUCING SEAFORTH RESIDENCES</h1>
      <p className="sect1" style={{fontSize: "1.5em", textAlign: "center"}}> Presenting five exclusive apartments commanding breath taking views across Middle Harbour, the Seaforth Residences is the ideal address for those looking to downsize whilst combining luxury, contemporary style and local convenience. Offering oversized two and three bedroom apartments all with terraces and one full-floor penthouse set in the vibrant heart of Seaforth Village, only moments away from shops, cafes, transport options. Granting a rare opportunity to own signature architectural living in a sought after northern beaches locale.</p>
      {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/571-575_SydneyRd_S030_unit201Living_FINAL2500.jpg")}
                ></img>
              </div> */}
      {/* <div className="section section-navbars">
        <div id="navbar">
          <div
            className="navigation-example"
            style={{
              backgroundImage: "url(" + require("assets/img/bg7.jpg") + ")",
            }}
          >
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Navbars;
