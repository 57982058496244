import React from "react";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Navbars() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
      <p className="under-text" style={{fontSize: "1.5em", textAlign: "center", marginBottom: "100px", marginTop: "50px"}}> To invite light and a sense of space into these lifestyle residences, the natural environment has been carefully considered from inception. The choices for luxury finishes, impeccable design, the minimalist modern lines which inspired the award-winning architects in the crafting of the Seaforth Residences have been reflective of the surroundings. The soft edges also represent a conscious design choice embracing a finishes selection blending natural stone and timbers juxtaposed with the innovative use of steel and concrete. Entertain effortlessly in the superbly appointed, open stone kitchens. Indulge in the generously detailed and proportioned ensuites and bathrooms. From their elevated position, expansive indoor-outdoor terraces capture broad sweeping views over Middle Harbour</p>
      <h1 id="team" style={{marginTop: "100px", textAlign: "center", marginBottom: "30px"}}> THE DESIGN TEAM</h1>
        <p style={{fontSize: "1.5em", textAlign: "center", marginBottom: "100px"}}> PBD Architects have a passion for Architecture and are committed to all things Design. A strong desire to learn and understand each client’s needs from project inception, ensures the final built form reflects not only the Architects early vision for the site but also the what the client envisions for their better living arrangements and lifestyle requisites. PBD, award- winning practice are committed to providing an honest, reliable and personalized service in which balancing the financial and management goals of clients with quality architecture as primary concern.</p>
        <img
          alt="..."
          src={require("assets/img/PBD+ARCHITECTS.png")}
          style = {{height:"300px", justifySelf: "center", display: "block", margin: "auto", marginTop: "-110px", marginBottom: "-130px"}}
        ></img>
        <p style={{fontSize: "1.5em", textAlign: "center", marginBottom: "100px", marginTop: "30px"}}> PBD’s clients range from individual investors and local developers to major internationally-listed development corporations. These relationships have seen PBD manage the interests of international clients for potential projects in the vicinity of $350 million.</p>
        <img
          alt="..."
          src={require("assets/img/571-575_SydneyRd_S030_unit 201_Bathroom_FINAL2500.jpg")}
          style= {{margin: "auto", width: "80%", display: "block", marginBottom: "50px"}}
        ></img>
      <div className="hero-images-container" style = {{display: "flow-root", margin: "auto auto 50px auto", width: "80vw"}}>
        <div className="imageFloat" style = {{ margin:"auto", maxWidth: "900px"}}> 
          <img
            alt="..."
            src={require("assets/img/JUFRAN.png")}
            class = "logo-juf"
            style={{width: "170px"}}
          ></img>
          
          <img
            alt="..."
            src={require("assets/img/BPR_VERT_Block.png")}
            class="logo-bel"
            style={{height: "100px",margin:"auto" }}
          ></img>
        </div>
      </div>
    </>
  );
}

export default Navbars;
