import React from "react";
import emailjs from 'emailjs-com';
// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip, Input, InputGroup, InputGroupAddon, InputGroupText, FormGroup, Label } from "reactstrap";

// core components

function Download() {
  const [firstFocus, setFirstFocus] = React.useState("");
  const [lastFocus, setLastFocus] = React.useState("");
  const [emailFocus, setEmailFocus] = React.useState("");
  const [phoneFocus, setPhoneFocus] = React.useState("");
  const [postCodeFocus, setPostCodeFocus] = React.useState("");
  const [internatFocus, setInternatFocus] = React.useState(false);
  var templateParams = {
    from_name: firstFocus + " " + lastFocus,
    email: emailFocus,
    internat: internatFocus,
    phone: phoneFocus,
    post_code: postCodeFocus
};
  const handleEmail = () => {
    alert("Your enquiry has been sent");
    emailjs.send('gmail_sea', 'template_WUGtGOvC', templateParams, 'user_NouhsCX7MRf89moPtOnWA')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }
  return (
    <>
      <div
        className="section section-download"
        data-background-color="torquise"
        id="download-section"
      >
        <Container>
          <Row className="text-center mt-5">
            <Col className="ml-auto mr-auto" md="8">
              <h2 style={{marginTop: "-80px"}}>Enquire Now</h2>
              <h5 style={{margin: "30px auto 15px"}}>
                VIEW BY APPOINTMENT
              </h5>
              <h5 style={{margin: "30px auto 15px"}}>
                First Name:*
              </h5>
              <InputGroup
                    className={
                      "no-border" + (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="First Name..."
                      type="text"
                      onChange={e => setFirstFocus(e.target.value)}
                    ></Input>
              </InputGroup>
              <h5 style={{margin: "30px auto 15px"}}>
                Last Name:*
              </h5>
              <InputGroup
                    className={
                      "no-border" + (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons text_caps-small"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Last name..."
                      type="text"
                      onChange={e => setLastFocus(e.target.value)}
                    ></Input>
              </InputGroup>
              <h5 style={{margin: "30px auto 15px"}}>
                Phone:*
              </h5>
              <InputGroup
                    className={
                      "no-border" + (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons text_caps-small"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone Number..."
                      type="text"
                      onChange={e => setPhoneFocus(e.target.value)}
                    ></Input>
              </InputGroup>
              <h5 style={{margin: "30px auto 15px"}}>
                Email:*
              </h5>
              <InputGroup
                    className={
                      "no-border" + (emailFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email..."
                      type="text"
                      onChange={e => setEmailFocus(e.target.value)}
                    ></Input>
              </InputGroup>
              <br/>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" 
                  checked={internatFocus}
                  onChange={() => setInternatFocus(!internatFocus)}></Input>
                  <span className="form-check-sign"></span>
                  International Equiry (Outside Australia)
                </Label>
              </FormGroup>
              <br/>
              <h5 style={{margin: "30px auto 15px"}}>
                Postcode/Suburb:*
              </h5>
              <InputGroup
                    className={
                      "no-border" + (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons text_caps-small"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Post Code..."
                      type="text"
                      onChange={e => setPostCodeFocus(e.target.value)}
                    ></Input>
              </InputGroup>
            </Col>
            <Col md="12">
              <br/>
              <Button
                color="primary"
                onClick={handleEmail}
                outline
                role="button"
                size="lg"
                target="_blank"
              >
                SUBMIT
              </Button>
              <h5 style={{margin: "10px auto"}}>
                * Mandatory fields
              </h5>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row className="justify-content-md-center sharing-area text-center" style={{fontSize: "0.4em"}}>
            <Col className="text-center" lg="20" md="20">
              <p>This information has been obtained from sources believed reliable. We have not verified it and make no guarantee, warranty or representation about it.
Any projections, opinions, assumptions or estimates used are for example only and do not represent the current or future performance of the property. You and your advisors should conduct a careful, independent investigation of the property to determine to your satisfaction the suitability of the property for your needs.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Download;
