import React from "react";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Navbars() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
    <div
        style={{backgroundColor: "#eaf0ed"}}
      >
      <h1 style={{paddingTop: "100px", textAlign: "center"}}> SEAFORTH RESIDENCES</h1>
      <p style={{fontSize: "1.5em", textAlign: "center", paddingBottom: "100px", marginBottom: "0"}}> The Seaforth Residences is developed by Jufran Investments a Sydney based practice known for the delivery of high-end residential projects on the Northern Beaches of Sydney. Jufran projects are centred on a balance between function and aesthetics. Their recognisable and established successful developments are a result of more than 20 years’ experience combined in the team and together with Belle Property can provide all the assistance residents and investors require in their journey to making better and more suitable lifestyle choice.</p>
    </div>
      {/* <div className="hero-images-container" style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%", marginTop: "-16px"}}>
          <img
                  alt="..."
                  src={require("assets/img/istockphoto-941224364-1024x1024 (1).jpg")}
          />
      </div> */}
      {/* <div className="section section-navbars">
        <div id="navbar">
          <div
            className="navigation-example"
            style={{
              backgroundImage: "url(" + require("assets/img/bg7.jpg") + ")",
            }}
          >
          </div>
        </div>
      </div> */}
      
    </>
    
  );
}

export default Navbars;
