import React from "react";
import { Parallax } from 'react-scroll-parallax';
// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components

const items = [
  {
    src: require("assets/img/Aerial.jpg"),
    altText: "",
    caption: "1",
  },
  {
    src: require("assets/img/iStockNew.jpg"),
    altText: "",
    caption: "2",
  }
];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section" id="carousel" style={{zIndex:"2", padding: 0, height: "100%", paddingBottom: "10vh"}}>
        <Container style= {{height: "100%", width: "100vw", maxWidth: "100vw"}}>
          <Row className="justify-content-center" style={{width: "100vw"}}>
            <Col lg="auto" style={{padding: "0"}}>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                style={{width: "100vw", height: "105%"}}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map((item) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                      style={{width: "100vw", height: "100%"}}
                    >
                      <img src={item.src} alt={item.altText} style={{width: "100vw"}}/>
                      <div className="carousel-caption d-none d-md-block">
                        <h5 className="numcap" onClick={(e) => {
                            e.preventDefault();
                            next();
                          }}> &lt; {item.caption} / 2 	&gt;</h5>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
        
      </div>
    </>
  );
}

export default CarouselSection;
