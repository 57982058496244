import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// sections for this page
import Images from "./index-sections/Images.js";
import BasicElements from "./index-sections/BasicElements.js";
import Section1 from "./index-sections/Section1.js";
import Carousel1 from "./index-sections/Carousel1.js";
import Carousel2 from "./index-sections/Carousel2.js";
import Section2 from "./index-sections/Section2.js";
import Section3 from "./index-sections/Section3.js";
import Section4 from "./index-sections/Section4.js";
import Download from "./index-sections/Download.js";
import { ParallaxProvider } from 'react-scroll-parallax';
function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });// fontSize: "1.7vw",
  return (
    <>
      <IndexNavbar />
      <ParallaxProvider>
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
        <p className="sidetext" style={{position: "fixed",  lineHeight: "2vw",color: "#93B6B7", right: "2vw", top: "100vh" ,textAlign:"center", margin:"auto", transform: "rotate(90deg)", transformOrigin: "100% 0%", zIndex: "1", width: "100vh"}}> A rare opportunity to own signature architectural living</p>
        <p className="sidetext" style={{position: "fixed", lineHeight: "2vw",color: "#93B6B7", left: "calc(4vw)", top: "0vh", textAlign:"center" , margin:"auto", transform: "rotate(90deg)", transformOrigin: "0% 0%", zIndex: "1", width: "100vh"}}> Exclusive apartments commanding breath taking views</p>
          {/* <Images />
          <BasicElements /> */}
          <Section1 />
          <Carousel1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Carousel2 />
          {/* <Tabs />
          <Pagination />
          <Notifications />
          <Typography />
          <Javascript />
          
          <NucleoIcons />
          <CompleteExamples />
          <SignUp />
          <Examples /> */}
          <Download />
        </div>
        <DarkFooter />
      </div>
      </ParallaxProvider>
    </>
  );
}

export default Index;
