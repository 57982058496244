import React from "react";
import { Parallax } from 'react-scroll-parallax';
// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components
//, paddingRight: "15vw", marginRight: "-10vw", transform: "rotate(90deg)", transformOrigin: "100% 0"
const items = [
  {
    src: require("assets/img/571-575_SydneyRd_S030_unit201Living_FINAL2500.jpg"),
    altText: "",
    caption: "1",
  },
  {
    src: require("assets/img/571-575_SydneyRd_S040_unit201_Kitchen_FINAL2500.jpg"),
    altText: "",
    caption: "2",
  }
];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section" id="carousel">
        <Container id="interior1" style= {{ marginRight: "5vw", marginLeft:"5vw"}}>
            <Row className="justify-content-center" style={{width: "90vw"}}>
              <Col lg="auto">
              <img src={require("assets/img/SEAFORTHResidences_Shell.png")} alt="shell" style={{position: "absolute", top:"-9%", left: "45%",width: "10%", zIndex:"3"}} />
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  style={{width: "100vw"}}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5 className="numcap" onClick={(e) => {
                            e.preventDefault();
                            next();
                          }}>&lt; {item.caption} / 2	&gt;</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>
              </Col>
            </Row>
            
        </Container>
        
      </div>
    </>
  );
}

export default CarouselSection;
