import React from "react";
import { Parallax } from 'react-scroll-parallax';
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Navbars() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  // let section2Img = React.createRef();
  // React.useEffect(() => {
  //   if (window.innerWidth > 991) {
  //     const updateScroll = () => {
  //       let a = window.innerHeight;
  //       let windowScrollTop = - window.pageYOffset / (a / 40) + 100;
        
  //       section2Img.current.style.transform =
  //         "translate3d(0," + windowScrollTop  + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });
  return (
    <>
      <div className="section section-navbars" style={{zIndex: "2"}}>
        <div id="navbar">
          <div
            className="sky-img"
            style={{
              backgroundImage: "url(" + require("assets/img/571-575_SydneyRd_S020_Courtyard_FINAL2000.jpg") + ")",
              
              backgroundPosition: "center 45%",
              backgroundSize: "cover"
            }}
          >
          <h1 id="lifestyle" style={{paddingTop: "60px", textAlign: "center"}}>  CONTEMPORARY WITH <br/> IMPECCABLE FINISHES</h1>
          <p className="sky-text" style={{fontSize: "1.5em", textAlign: "center", marginBottom: "100px"}}> To invite light and a sense of space into these lifestyle residences, the natural environment has been carefully considered from inception. The choices for luxury finishes, impeccable design, the minimalist modern lines which inspired the award-winning architects in the crafting of the Seaforth Residences have been reflective of the surroundings. The soft edges also represent a conscious design choice embracing a finishes selection blending natural stone and timbers juxtaposed with the innovative use of steel and concrete. Entertain effortlessly in the superbly appointed, open stone kitchens. Indulge in the generously detailed and proportioned ensuites and bathrooms. From their elevated position, expansive indoor-outdoor terraces capture broad sweeping views over Middle Harbour</p>
          </div>
        
        </div>
      </div>
    </>
  );
}

export default Navbars;
